import { EditorType } from '@wix/platform-editor-sdk';

let editorType;
const cache = {};
export const editorReady = async (editorSDK, appDefinitionId, { origin }) => {
  editorType = origin?.type;

  // const log = (type, args) => {
  //   console.log(
  //     'Pro Gallery Editor script, End of Handler in editorScript called with type:',
  //     type,
  //     { ...args },
  //   );
  // };
  const hadnleGalleryDuplicationEvent = (args) => {
    if (args?.detail.componentRef.type === 'DESKTOP') {
      const originCompId = args?.detail?.originalComponentId;
      const compId = args?.detail?.componentRef?.id;
      if (!compId || compId.includes('TPAMultiSection')) {
        return;
      }
      return createGallery({ compId, originCompId }, editorSDK);
    }
  };

  // await editorSDK.addEventListener('widgetDuplicated', hadnleGalleryDuplicationEvent);
  await editorSDK.addEventListener(
    'widgetAdded',
    hadnleGalleryDuplicationEvent,
  );
  // await editorSDK.addEventListener('widgetPasted', hadnleGalleryDuplicationEvent);
  // await editorSDK.addEventListener('connectedComponentDuplicated', (args) => {
  //   // console.log(
  //   //   'Pro Gallery Editor script, connectedComponentDuplicated is fired, implementation is on the paste event',
  //   // );
  //   // log('connectedComponentDuplicated', args);
  // });
  // await editorSDK.addEventListener('connectedComponentAddedToStage', (args) => {
  //   // console.log(
  //   //   'Pro Gallery Editor script, connectedComponentAddedToStage is fired, implementation is on the paste event',
  //   // );
  //   // log('connectedComponentAddedToStage', args);
  // });
  // await editorSDK.addEventListener('connectedComponentPasted', (args) => {
  //   // console.log(
  //   //   'Pro Gallery Editor script, connectedComponentPasted is fired, implementation is on the paste event',
  //   // );
  //   // log('connectedComponentPasted', args);
  // });
  // const pageCreateGalleries = async (args) => {
  //   const oldControllers = await editorSDK.controllers.listControllers('', {
  //     pageRef: args.detail.originalPageRef,
  //     includeTPAWidget: true,
  //   });
  //   const newControllers = await editorSDK.controllers.listControllers('', {
  //     pageRef: args.detail.duplicatedPageRef,
  //     includeTPAWidget: true,
  //   });
  //   if (oldControllers?.length > 0) {
  //     const createGalleryRequests = oldControllers.map(
  //       (oldControllerRef, index) => {
  //         const originCompId = oldControllerRef.controllerRef.id;
  //         const compId = newControllers[index]
  //           ? newControllers[index].controllerRef.id
  //           : undefined;
  //         if (originCompId && compId) {
  //           return createGallery({ compId, originCompId }, editorSDK);
  //         } else {
  //           return Promise.resolve();
  //         }
  //       },
  //     );
  //     return Promise.all(createGalleryRequests);
  //   } else {
  //     return;
  //   }
  // };
  // await editorSDK.addEventListener('pageDuplicated', pageCreateGalleries);
  // await editorSDK.addEventListener('pageAdded', pageCreateGalleries);
};

export const exports = (editorSDK) => ({
  editor: {
    beforeWidgetAdded: async (args) => {
      // const { compId, originCompId, originInstanceId } = args;
      // return createGallery(
      //   { compId, originCompId, originInstanceId },
      //   editorSDK,
      // );
    },
  },
});

const createGallery = async (
  { compId, originCompId, originInstanceId },
  editorSDK,
) => {
  if (!compId || cache[compId]) {
    return;
  } else {
    cache[compId] = true;
  }
  const editorName = convertEditorType(editorType);
  try {
    if (editorName === 'EDITORX' || editorName === 'EDITOR') {
      const requestUrl =
        'https://editor.wix.com/_api/pro-gallery-editor-webapp/v1/app/galleries'; // This was tested with editor. not editorx. might have CORS when we start testing it to
      const instance = await editorSDK.document.info.getAppInstance('token');

      const response = await fetch(requestUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: instance,
        },
        body: JSON.stringify({
          compId,
          originCompId,
          originInstanceId,
          editorType: editorName,
        }),
      });
      const data = await response.json();
      if (typeof data?.galleryId === 'string') {
        editorSDK.application.livePreview.refresh('', {
          shouldFetchData: true,
          source: 'AFTER_GALLERY_CREATED',
        });
        return Promise.resolve();
      } else {
        throw new Error('no galleryId returned from the server');
      }
    }
  } catch (e) {
    console.error(
      'Could not create a new gallery in the server using the provided data',
      JSON.stringify({
        compId,
        originCompId,
        originInstanceId,
        editorType: editorName,
      }),
      e,
    );
  }
};
const convertEditorType = (type) => {
  switch (type) {
    case EditorType.Classic:
      return 'EDITOR';
    case EditorType.Responsive:
      return 'EDITORX';
    case EditorType.ADI:
      return 'ADI';
    default:
      // there are two more types in the possible EditorTypes, our server doesnt know about them
      return type;
  }
};
